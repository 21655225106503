<template>
  <div class="home-container">
    <el-card class="content-card">
      <!-- 添加 logo 图片 -->
      <div class="image-container">
        <img :src="sharkImageUrl" alt="Shark Image" class="shark-image"/>
      </div>

      <div class="description">
        <p>鲨鱼发现有许多旁门左派开启了 ☞<b>山寨模式</b>☜</p>
        <p>套用鲨鱼的头像和朋友圈</p>
        <p>为了避免各位宝子被山寨欺骗</p>
        <p>鲨鱼开启了<b>验真伪模式</b>！</p>
        <p>↓↓↓一秒辨别真假↓↓↓</p>
      </div>

      <el-form size="small" :model="form" ref="queryForm" class="query-form">
        <el-form-item prop="account">
          <el-input v-model="form.account" placeholder="请输入微信号/手机号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryAccount" style="background-color: #67C23A; color: white;">一键查询</el-button>
        </el-form-item>
        <!-- 修改后的显示区域 -->
        <el-form-item v-if="queryResult !== null">
          <div :class="['result-message', queryResult.tf ? 'success' : 'error']">
            {{ queryResult.tf ? '此微信号属于 鲨鱼票务团队' : '此微信号不属于 鲨鱼票务团队' }}
          </div>
        </el-form-item>
        <!-- 新增的备注显示 -->
<!--        <el-form-item v-if="queryResult !== null && queryResult.notes">-->
<!--          <div class="notes">账号备注：<b>{{ queryResult.notes }}</b></div>-->
<!--        </el-form-item>-->
      </el-form>
    </el-card>
  </div>
</template>



<script>
export default {
  name: "FrontHome",
  data() {
    return {
      form: {
        account: ''
      },
      queryResult: null,
      sharkImageUrl: require('@/assets/shayu.jpg')
    };
  },
  methods: {
    queryAccount() {
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return;
      }
      this.request.get("/account/query", {params: {account: this.form.account}})
          .then(res => {
            if (res.code === '200') {
              this.queryResult = res.data;
              // this.$message.success("鉴定完成");
            } else {
              this.$message.error(res.msg || "查询失败");
            }
          })
          .catch(error => {
            this.$message.error("请求失败");
          });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-container {
  display: flex;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
}

.content-card {
  width: 100%;
  max-width: 420px; /* 最大宽度 */
  padding: 20px;
  box-sizing: border-box;
  margin: 10px auto;
}

.description {
  text-align: center;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.5;

  p {
    margin: 5px 0;
  }
}

.query-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-form-item {
    width: 100%;
    max-width: 300px;
  }

  .el-button {
    width: 100%;
  }
}

.shark-image {
  max-width: 100%;
  height: auto;
  max-height: 100px;
  display: block;
  margin: 0 auto;
}

.notes {
  font-size: 14px;
  text-align: center;
}

.result-message {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.result-message.success {
  color: #67C23A;
  border: 1px solid #67C23A;
}

.result-message.error {
  color: #f56c6c;
  border: 1px solid #f56c6c;
}
</style>

